import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react"
import {
  TextBodyProps,
  UnstyledButton,
  useIsLessThanMd,
  VerticalAligned,
  VerticalAlignedProps,
  Spinner,
  classNames,
  Input,
  InputProps,
  CenterAligned,
} from "@opensea/ui-kit"
import useSize from "@react-hook/size"
import { noop, range } from "lodash"
import { rgba } from "polished"
import { useClickAway, useKeyPressEvent } from "react-use"
import styled, { css } from "styled-components"
import { ShowMoreIcon } from "@/components/common/ShowMoreIcon"
import { Block } from "@/design-system/Block"
import { DropdownProps, Dropdown, RenderItem } from "@/design-system/Dropdown"
import {
  MobileDropdown,
  MobileDropdownProps,
  MobileDropdownSelectProps,
} from "@/features/account/components/MobileDropdown"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useTranslate } from "@/hooks/useTranslate"
import { isInsideElement, isInsideRef } from "@/lib/helpers/dom"
import { UnreachableCaseError } from "@/lib/helpers/type"
import { inter } from "@/styles/fonts"
import { themeVariant } from "@/styles/styleUtils"
import { AvatarProps } from "../Avatar"
import { Item, ItemProps } from "../Item"
import { ItemSkeleton } from "../ItemSkeleton"
import { preventFontSizeZoomStyles } from "../utils"

type CommonProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "ref"
> &
  Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "ref"
  >

export type SelectOption<Value extends string = string> = {
  label: string
  value: Value
  key?: string
  avatar?: AvatarProps
  description?: string
  disabled?: boolean
  startEnhancer?: ReactNode
  date?: Date
}

export type StringSelectOption = Omit<SelectOption, "value"> & { value: string }

export type LoadingConfiguration = {
  avatar?: boolean
  title?: boolean
  description?: boolean
  count?: number
}

type RenderSelectedItem<T> = (props: { item?: T }) => React.ReactNode

export type SelectProps<
  Value extends string = string,
  Option extends SelectOption<Value> = SelectOption<Value>,
> = {
  autoComplete?: string
  disabled?: boolean
  options: ReadonlyArray<Option>
  /**
   * Options that are supported, but not necessarily displayed, Used for
   * providing labels for options that are not currently displayed.
   *
   * Use options above for options that are displayed.
   */
  optionsSupported?: ReadonlyArray<Option>
  className?: string
  onSelect: (option: Option | undefined) => unknown
  placeholder?: string
  value?: Value
  renderItem?: RenderItem<Option>
  renderSelectedItem?: RenderSelectedItem<Option>
  startEnhancer?: React.ReactNode
  endEnhancer?: React.ReactNode
  clearable?: boolean
  searchFilter?: (option: Option, query: string) => boolean
  readOnly?: boolean
  emptyText?: string
  autoFocus?: boolean
  bordered?: boolean
  variant?: "search" | "item"
  name?: string
  id?: string
  isLoading?: boolean | LoadingConfiguration
  onChange?: (value: string) => unknown
  style?: React.CSSProperties
  excludeSelectedOption?: boolean
  maxHeight?: string
  matcher?: (option: Option, value: Value | undefined) => boolean
  onOpenChange?: (open: boolean) => unknown
  hideOnScroll?: boolean
  overrides?: {
    Dropdown?: {
      props: Pick<
        Partial<DropdownProps<Value>>,
        "popperOptions" | "appendTo" | "placement" | "overflowY" | "touch"
      >
    }
    MobileDropdown?: {
      props: Partial<MobileDropdownProps>
      Select?: {
        props: Partial<MobileDropdownSelectProps<Option>>
      }
    }
    ContentItem?: {
      props: Partial<ItemProps>
    }
    ContentLabel?: {
      props: Partial<VerticalAlignedProps>
    }
    ContentLabelTitle?: {
      props: Partial<TextBodyProps>
    }
    Input?: {
      props: Partial<InputProps>
    }
  }
  "aria-label"?: string
  tabIndex?: number
  mobileTitle?: string
}

export const Select = <
  Value extends string,
  Option extends SelectOption<Value>,
>({
  autoComplete,
  disabled,
  className,
  placeholder,
  options,
  optionsSupported,
  onSelect,
  renderItem,
  renderSelectedItem,
  value,
  startEnhancer,
  endEnhancer,
  clearable = true,
  searchFilter = (option, query) =>
    !!(
      option.label.toLowerCase().includes(query) ||
      option.description?.toLowerCase().includes(query)
    ),
  readOnly,
  emptyText,
  autoFocus,
  bordered = true,
  variant = "search",
  name,
  id,
  isLoading = false,
  onChange,
  style,
  excludeSelectedOption = false,
  maxHeight,
  matcher = (option, value) => option.value === value,
  onOpenChange,
  hideOnScroll,
  overrides,
  "aria-label": ariaLabel,
  tabIndex,
  mobileTitle,
}: SelectProps<Value, Option>) => {
  const isPressed = useRef(false)
  const containerRef = useRef<HTMLElement>(null)
  const [query, setQuery] = useState("")
  const sanitizedQuery = query.toLowerCase()
  const [minWidth] = useSize(containerRef)
  const dropdownRef = useRef<HTMLUListElement>(null)
  const isMobile = useIsLessThanMd()
  const {
    isOpen: activeIsOpen,
    open: activeOpen,
    close: activeClose,
    setIsOpen,
  } = useIsOpen()

  // Toggle trigger control between desktop and mobile
  const isOpen = isMobile ? false : activeIsOpen
  const open = isMobile ? noop : activeOpen
  const close = isMobile ? noop : activeClose

  const t = useTranslate("designSystem")

  const selectedValue = useMemo(
    () =>
      options.find(o => matcher(o, value)) ??
      optionsSupported?.find(o => matcher(o, value)),
    [options, optionsSupported, value, matcher],
  )

  const isInsideDropdown = (relatedTarget: EventTarget | null) => {
    return isInsideElement(
      dropdownRef.current?.closest("[data-tippy-root]") ?? null,
      relatedTarget,
    )
  }

  useKeyPressEvent("Escape", isOpen ? close : undefined)
  useClickAway(containerRef, event => {
    if (!isInsideDropdown(event.target)) {
      close()
    }
  })

  const shownOptions = useMemo(() => {
    const shouldNotFilter = readOnly || variant === "item"
    const results = shouldNotFilter
      ? options
      : options.filter(o => searchFilter(o, sanitizedQuery))

    return excludeSelectedOption
      ? results.filter(option => !matcher(option, value))
      : results
  }, [
    sanitizedQuery,
    options,
    excludeSelectedOption,
    value,
    variant,
    readOnly,
    searchFilter,
    matcher,
  ])

  useEffect(() => {
    onOpenChange?.(isOpen)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    setQuery(selectedValue?.label ?? value ?? "")
  }, [selectedValue, value])

  useEffect(() => {
    if (hideOnScroll && isOpen) {
      window.addEventListener("scroll", close)
    }
    return () => {
      if (hideOnScroll && isOpen) {
        window.removeEventListener("scroll", close)
      }
    }
  }, [hideOnScroll, close, isOpen])

  const renderDropdownItem: RenderItem<Option> = props => {
    const { Item, item } = props

    const onBlur = (event: { relatedTarget: EventTarget | null }) => {
      if (!isInsideDropdown(event.relatedTarget)) {
        close()
      }
    }

    const onClick = () => {
      onSelect(item)
      if (item.value === value && item.label !== query) {
        setQuery(item.label)
      }
      close()
    }

    if (renderItem) {
      return renderItem({ onBlur, onClick, ...props })
    }

    return (
      <Item
        disabled={item.disabled}
        key={item.key ?? item.value}
        onBlur={onBlur}
        onClick={onClick}
      >
        {item.avatar && <Item.Avatar {...item.avatar} />}
        <Item.Content>
          <Item.Title className="flex flex-row items-center">
            {item.startEnhancer && <Block>{item.startEnhancer}</Block>}
            {item.label}
          </Item.Title>
          {item.description && (
            <Item.Description>{item.description}</Item.Description>
          )}
        </Item.Content>
      </Item>
    )
  }

  const renderLoadingItems = (loading: true | LoadingConfiguration) => {
    const configuration: LoadingConfiguration =
      loading === true ? { title: true } : loading

    return range(0, configuration.count || 3).map(index => (
      <ItemSkeleton aria-label="loading option" key={index}>
        {configuration.avatar && <ItemSkeleton.Avatar />}
        <ItemSkeleton.Content>
          {configuration.title && <ItemSkeleton.Title />}
          {configuration.description && <ItemSkeleton.Description />}
        </ItemSkeleton.Content>
      </ItemSkeleton>
    ))
  }

  const renderMobileDropdown = () => {
    return (
      <MobileDropdown
        isOpen={activeIsOpen}
        title={mobileTitle || t("select.mobileTitle", "Select")}
        onClose={activeClose}
        {...overrides?.MobileDropdown?.props}
      >
        <MobileDropdown.Select
          isSelected={option => option.value === value}
          options={options}
          onSelect={option => {
            onSelect(option)
            activeClose()
          }}
          {...overrides?.MobileDropdown?.Select?.props}
        />
      </MobileDropdown>
    )
  }

  const renderDropdownProps = (): DropdownProps<Option> => {
    if (shownOptions.length === 0 && !isLoading) {
      return {
        content: function NoResults() {
          return (
            <CenterAligned className="p-8">
              {emptyText || "No results"}
            </CenterAligned>
          )
        },
      }
    }

    return {
      content: function Results({ List, Item, close }) {
        return (
          <List ref={dropdownRef}>
            {shownOptions.map(item =>
              renderDropdownItem({ Item, item, close }),
            )}

            {isLoading ? renderLoadingItems(isLoading) : null}
          </List>
        )
      },
    }
  }

  const showMoreIcon = (
    <VerticalAligned>
      <ShowMoreIcon isActive={isOpen} />
    </VerticalAligned>
  )

  const commonProps: CommonProps = {
    "aria-label": ariaLabel,
    "aria-haspopup": true,
    onClick: () => setIsOpen(prev => !prev),
    onFocus: () => {
      if (!isPressed.current) {
        open()
      }
      isPressed.current = false
    },
    onMouseDown: () => {
      isPressed.current = true
    },
  }

  const renderSearchVariant = () => {
    return (
      <SelectInput
        $bordered={bordered}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={classNames(readOnly && "cursor-pointer", className)}
        clearOnEscape
        clearable={clearable}
        disabled={disabled}
        endEnhancer={
          isLoading ? (
            <VerticalAligned>
              <Spinner />
            </VerticalAligned>
          ) : (
            endEnhancer ?? showMoreIcon
          )
        }
        id={id}
        name={name}
        overrides={{
          Input: {
            className: classNames(readOnly && "cursor-pointer"),
          },
        }}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={containerRef as React.RefObject<HTMLInputElement>}
        startEnhancer={
          startEnhancer ? (
            <VerticalAligned className="mr-3">{startEnhancer}</VerticalAligned>
          ) : undefined
        }
        style={style}
        tabIndex={tabIndex}
        value={query}
        onBlur={event => {
          if (isPressed.current) {
            return
          }
          // Dont clear "invalid" option if one was just selected
          if (isInsideDropdown(event.relatedTarget)) {
            return
          }

          if (!options.some(o => o.label === query)) {
            if (value) {
              onSelect(undefined)
            } else {
              setQuery("")
            }
          }

          // Close if focus went outside and not if e.g. close icon got focus
          if (!isInsideRef(containerRef, event.relatedTarget)) {
            close()
          }
        }}
        onChange={event => {
          setQuery(event.currentTarget.value)
          if (!event.currentTarget.value && value) {
            onSelect(undefined)
          }
          onChange?.(event.currentTarget.value)
          open()
        }}
        {...commonProps}
        {...overrides?.Input?.props}
      />
    )
  }

  const renderItemVariant = () => {
    return (
      <UnstyledButton
        className="w-full"
        disabled={disabled}
        name={name}
        ref={containerRef as React.RefObject<HTMLButtonElement>}
        {...commonProps}
        onBlur={(event: React.FocusEvent<HTMLButtonElement>) => {
          if (!isInsideDropdown(event.relatedTarget)) {
            close()
          }
        }}
      >
        <SelectItem
          // @ts-expect-error Styled components not typing correctly
          $bordered={bordered}
          $isOpen={isOpen}
          className={classNames("py-3 pl-4 pr-3", className)}
          {...overrides?.ContentItem?.props}
        >
          {renderSelectedItem ? (
            renderSelectedItem({ item: selectedValue })
          ) : (
            <>
              {startEnhancer && (
                <VerticalAligned className="mr-3">
                  {startEnhancer}
                </VerticalAligned>
              )}
              <input placeholder={placeholder} type="hidden" value={query} />
              {selectedValue?.avatar && (
                <Item.Avatar {...selectedValue.avatar} />
              )}
              <Item.Content {...overrides?.ContentLabel?.props}>
                <Item.Title {...overrides?.ContentLabelTitle?.props}>
                  {selectedValue?.label ?? value ?? placeholder}
                </Item.Title>
                {selectedValue?.description && (
                  <Item.Description>
                    {selectedValue.description}
                  </Item.Description>
                )}
              </Item.Content>
            </>
          )}
          <Item.Side className="max-w-[none]">
            {endEnhancer ?? showMoreIcon}
          </Item.Side>
        </SelectItem>
      </UnstyledButton>
    )
  }

  const renderContent = () => {
    switch (variant) {
      case "search":
        return renderSearchVariant()
      case "item":
        return renderItemVariant()
      default:
        throw new UnreachableCaseError(variant)
    }
  }

  return (
    <>
      {isMobile && renderMobileDropdown()}
      <Dropdown
        disabled={disabled}
        visible={isOpen}
        {...renderDropdownProps()}
        interactive
        maxHeight={maxHeight}
        minWidth={minWidth}
        offset={[0, 8]}
        touch
        {...overrides?.Dropdown?.props}
      >
        {renderContent()}
      </Dropdown>
    </>
  )
}

const SelectInput = styled(Input)<{ $bordered?: boolean }>`
  font-family: ${inter.style.fontFamily}, sans-serif;
  font-variant-ligatures: no-contextual;
  color: ${props => props.theme.colors.text.primary};
  ${preventFontSizeZoomStyles}

  ${props =>
    !props.$bordered &&
    css`
      border: none;
      box-shadow: none;

      :hover,
      :focus {
        border: none;
        box-shadow: none;
      }
    `};
`

export const SelectItem = styled(Item)<{
  $bordered?: boolean
  $isOpen?: boolean
}>`
  border-radius: ${props => props.theme.borderRadius.button};
  font-weight: 600;
  font-size: 16px;
  transition: all ease-in-out 0.25s;

  ${props =>
    props.$bordered
      ? css`
          border: 1px solid ${props.theme.colors.components.border.level2};

          :hover {
            background: none !important;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
            border-color: ${props =>
              props.theme.colors.components.border.level3};
          }

          :focus {
            border: 1px solid
              ${props => props.theme.colors.components.border.level3};
          }
        `
      : css`
          border: none;
          box-shadow: none;
        `};

  && {
    ${props =>
      themeVariant({
        variants: {
          dark: {
            backgroundColor: rgba(props.theme.colors.white, 0.04),
            border: "none",
            color: props.theme.colors.text,
            ":hover:not([disabled])": {
              backgroundColor: `${rgba(
                props.theme.colors.white,
                0.08,
              )} !important`,
              boxShadow: "none",
              border: "none !important",
            },
            ":active:not([disabled])": {
              backgroundColor: rgba(props.theme.colors.white, 0.12),
              border: "none !important",
            },
            ":focus:not([disabled])": {
              backgroundColor: props.$isOpen
                ? rgba(props.theme.colors.white, 0.12)
                : rgba(props.theme.colors.white, 0.04),
              border: "none !important",
            },
            ":disabled": {
              opacity: 0.4,
              backgroundColor: rgba(props.theme.colors.white, 0.04),
            },
          },
          light: {
            backgroundColor: rgba(props.theme.colors.smoke, 0.04),
            border: "none",
            color: props.theme.colors.text,
            ":hover:not([disabled])": {
              backgroundColor: `${rgba(
                props.theme.colors.smoke,
                0.08,
              )} !important`,
              boxShadow: "none",
              border: "none !important",
            },
            ":active:not([disabled])": {
              backgroundColor: rgba(props.theme.colors.smoke, 0.12),
              border: "none !important",
            },
            ":focus:not([disabled])": {
              backgroundColor: props.$isOpen
                ? rgba(props.theme.colors.smoke, 0.12)
                : rgba(props.theme.colors.smoke, 0.04),
              border: "none !important",
            },
            ":disabled": {
              opacity: 0.4,
              backgroundColor: rgba(props.theme.colors.smoke, 0.04),
            },
          },
        },
      })}
  }
` as typeof Item
