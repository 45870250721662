import React from "react"
import { BottomSheet, BottomSheetProps } from "@/design-system/BottomSheet"
import {
  MobileDropdownDivider,
  MobileDropdownItem,
  MobileDropdownList,
  MobileDropdownBody,
  MobileDropdownHeader,
  MobileDropdownTitle,
} from "./elements"
import { MobileDropdownSelect } from "./MobileDropdownSelect.react"

export type MobileDropdownProps = Pick<
  BottomSheetProps,
  "onClose" | "isOpen"
> & { title?: string; children: React.ReactNode }

const _MobileDropdown = ({
  onClose,
  isOpen = false,
  children,
  title,
}: MobileDropdownProps) => {
  return (
    <BottomSheet
      isOpen={isOpen}
      onClose={() => {
        onClose?.()
      }}
    >
      {title ? (
        <>
          <MobileDropdown.Header>
            <MobileDropdown.Title>{title}</MobileDropdown.Title>
          </MobileDropdown.Header>
          <MobileDropdown.Body>{children}</MobileDropdown.Body>
        </>
      ) : (
        children
      )}
    </BottomSheet>
  )
}

export const MobileDropdown = Object.assign(_MobileDropdown, {
  Select: MobileDropdownSelect,
  Item: MobileDropdownItem,
  List: MobileDropdownList,
  Divider: MobileDropdownDivider,
  Header: MobileDropdownHeader,
  Title: MobileDropdownTitle,
  Body: MobileDropdownBody,
})
