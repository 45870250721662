import React from "react"
import { Icon, IconProps } from "@opensea/ui-kit"
import styled from "styled-components"
import { useTranslate } from "@/hooks/useTranslate"
import { classNames } from "@/styles/styleUtils"

type Props = Omit<IconProps, "value" | "color"> & {
  isActive?: boolean
} & Pick<JSX.IntrinsicElements["i"], "aria-label">

export const ShowMoreIcon = ({
  isActive = false,
  className,
  ...rest
}: Props) => {
  const t = useTranslate("common")

  return (
    <ArrowIcon
      {...rest}
      $isActive={isActive}
      className={classNames("cursor-pointer", className)}
      title={
        isActive
          ? t("select.showMore.icon.isActive.label", "Show less")
          : t("select.showMore.icon.isInactive.label", "Show more")
      }
      value="keyboard_arrow_down"
    />
  )
}

const ArrowIcon = styled(Icon)<{ $isActive: boolean }>`
  color: ${props => props.theme.colors.text.primary};
  transform: ${props => (!props.$isActive ? undefined : "rotate(180deg)")};
  transition:
    transform 0.25s ease-in-out,
    background-color 0.25s ease-in-out;
`
