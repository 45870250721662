import styled, { css } from "styled-components"
import { BottomSheet } from "@/design-system/BottomSheet"
import {
  DropdownList,
  DropdownItem,
  DropdownDivider,
} from "@/design-system/Dropdown/elements"
import { isInteractiveElement } from "@/design-system/utils"
import { media } from "@/styles/styleUtils"

export const MobileDropdownDivider = styled(DropdownDivider)`
  :not(:last-child) {
    height: 16px;
    margin: 0;
    background-color: ${props =>
      props.theme.colors.components.elevation.level2.background};
  }
`

export const MobileDropdownItem = styled(DropdownItem)`
  ${props =>
    isInteractiveElement(props) &&
    !props.disabled &&
    css`
      :active:not([disabled]),
      :hover:not([disabled]) {
        background-color: ${props =>
          props.theme.colors.components.background.gray1};
      }
    `}
`

export const MobileDropdownList = styled(DropdownList)`
  background-color: ${props =>
    props.theme.colors.components.elevation.level2.background};
  padding-left: 16px;
  padding-right: 16px;

  ${MobileDropdownItem} {
    border-left: 1px solid
      ${props => props.theme.colors.components.border.level2};
    border-right: 1px solid
      ${props => props.theme.colors.components.border.level2};
    border-bottom: 1px solid
      ${props => props.theme.colors.components.border.level2};
    border-radius: 0;
  }

  ${MobileDropdownDivider} + li > ${MobileDropdownItem},
  > :first-child,
  > :first-child > li > ${MobileDropdownItem} :first-of-type {
    border-top: 1px solid
      ${props => props.theme.colors.components.border.level2};
    border-top-left-radius: ${props => props.theme.borderRadius.button};
    border-top-right-radius: ${props => props.theme.borderRadius.button};
  }
  .MobileDropdown-group-last {
    border-bottom: 1px solid
      ${props => props.theme.colors.components.border.level2};
    border-bottom-left-radius: ${props => props.theme.borderRadius.button};
    border-bottom-right-radius: ${props => props.theme.borderRadius.button};
  }
`

export const MobileDropdownHeader = styled(BottomSheet.Header)`
  border-bottom: none;
  min-height: 0;
  padding-top: 24px;
  padding-bottom: 0;
`

export const MobileDropdownTitle = styled(BottomSheet.Title)`
  margin-top: -2px;
  font-size: 18px;

  ${media({
    md: css`
      margin-top: 4px;
      font-size: 20px;
    `,
  })}
`

export const MobileDropdownBody = styled(BottomSheet.Body).attrs({
  lockedBodyScroll: true,
})`
  padding-left: 0;
  padding-right: 0;

  ${media({
    sm: css`
      padding-left: 8px;
      padding-right: 8px;
    `,
  })}
`
