import React from "react"
import { MaterialIcon } from "@opensea/ui-kit"
import styled from "styled-components"
import { RenderItemProps } from "@/design-system/Dropdown"
import { List } from "@/design-system/List"
import { SelectOption } from "@/design-system/Select"
import { MobileDropdownOption } from "."

export type MobileDropdownOptionBase = SelectOption & {
  icon?: MaterialIcon
}

export type MobileDropdownSelectProps<T> = {
  onSelect: (option: T) => unknown
  options: ReadonlyArray<T>
  isSelected: (option: T) => boolean
  keyName?: (option: T) => string
  renderItem?: (
    props: Omit<RenderItemProps<T>, "close" | "Item">,
  ) => React.ReactNode
  className?: string
}

export const MobileDropdownSelect = <T extends MobileDropdownOptionBase>({
  options,
  onSelect,
  className,
  isSelected,
  renderItem,
  keyName = option => option.value,
}: MobileDropdownSelectProps<T>) => {
  return (
    <StyledList className={className}>
      {options.map(option => {
        const { label, avatar, disabled } = option
        if (renderItem) {
          return renderItem({ item: option, onClick: () => onSelect(option) })
        }
        return (
          <MobileDropdownOption
            avatar={avatar}
            disabled={disabled}
            isSelected={isSelected(option)}
            key={keyName(option)}
            label={label}
            onSelect={() => {
              onSelect(option)
            }}
          />
        )
      })}
    </StyledList>
  )
}

const StyledList = styled(List).attrs({ showBorder: false })`
  border-radius: 0;

  > :first-child,
  > :first-child > button:first-of-type,
  > :first-child > a:first-of-type {
    border-radius: 0;
  }

  > :last-child,
  > :last-child > button:last-of-type,
  > :last-child > a:last-of-type {
    border-radius: 0;
  }
`
