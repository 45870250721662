import React, { ReactNode } from "react"
import styled from "styled-components"
import { AvatarProps } from "@/design-system/Avatar"
import { List } from "@/design-system/List"

type MobileDropdownOptionProps = {
  label: ReactNode
  isSelected?: boolean
  avatar?: AvatarProps
  disabled?: boolean
  onSelect?: () => unknown
}

export const MobileDropdownOption = ({
  label,
  isSelected,
  avatar,
  disabled,
  onSelect,
}: MobileDropdownOptionProps) => {
  return (
    <StyledListItem disabled={disabled} onClick={onSelect}>
      {avatar && <StyledListItemAvatar size={20} {...avatar} />}
      <List.Item.Content>
        <List.Item.Title
          size="medium"
          weight={isSelected ? "semibold" : "regular"}
        >
          {label}
        </List.Item.Title>
      </List.Item.Content>
      {isSelected && (
        <List.Item.Side>
          <StyledListItemAvatar className="mr-0" icon="check" size={24} />
        </List.Item.Side>
      )}
    </StyledListItem>
  )
}

const StyledListItem = styled(List.Item)`
  height: 56px;
  padding: 0 16px;
  background: ${props =>
    props.theme.colors.components.elevation.level2.background};
`

const StyledListItemAvatar = styled(List.Item.Avatar)`
  color: ${props => props.theme.colors.text.primary};
`
